import { Routes, Route, HashRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Page from '../Page/Page';
import './App.css';
import TopNavBarStyle1 from '../Components/TopNavBar/TopNavBarStyle1/TopNavBarStyle1';
import FooterStyle1 from '../Components/Footer/FooterStyle1';
import ProgressBarStyle1 from '../Components/ProgressBar/ProgressBarStyle1';
import { createElement, useState, useEffect } from 'react';
import TopNavBarStyle2 from '../Components/TopNavBar/TopNavBarStyle2/TopNavBarStyle2';
import DevToolGodMode from '../DevToolGodMode/DevToolGodMode';
import TopNavBarStyle3 from '../Components/TopNavBar/TopNavBarStyle3/TopNavBarStyle3';
import FooterStyle2 from '../Components/Footer/FooterStyle2';

function App() {

  const [exceptionMessage, setExceptionMessage] = useState("");

  const [siteSettings, setSiteSettings] = useState({
    site: {
      cssStyle: {},
      colors: {},
      currentLanguage: {
        code: "",
        displayName: "",
        englishName: "",
        direction: ""
      },
      languages: [
        {
          code: "",
          displayName: "",
          englishName: "",
          direction: ""
        }],
      oneTrust: {
        enabled: false,
        id: ""
      },
    },
    navBar: {
      type: "",
      cssStyle: {}
    },
    progressBar: {
      visible: false
    },
    footer: {
      type: "",
      cssStyle: {}
    },
    pages: [
      {
        name: "home",
        path: "/",
        progressBarIndex: 0,
        components: [
          {
            type: "ContentMarkdownWithCols",
            args: {
              cols: [
                { "content": "## Loading..." }
              ]
            }
          }]
      }
    ],
    custom: {
      css: ""
    }
  });

  function getParameterByName(name: string) {
    let url = window.location.href.replaceAll("#", "").replaceAll("//", "/").replaceAll(":/", "://");
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  function addOneTrust(id: string) {
    const script = document.createElement("script");
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.setAttribute("data-domain-script", id);
    script.async = true;

    /*
    const cookieList = document.createElement("div");
    script.setAttribute("id", "ot-sdk-cookie-policy");
    script.setAttribute("style", "width:800px; justify-content: center; margin:0 auto;");
    document.body.appendChild(cookieList)*/

    document.head.appendChild(script);
  }

  function addCustomCss(css: string) {
    const style = document.createElement("style");

    style.appendChild(document.createTextNode(css));

    document.head.appendChild(style);
  }
  function addCustomJs(js: string) {
    const script = document.createElement("script");
    script.append(document.createTextNode(js));
    script.async = true;
    document.head.appendChild(script);
  }

  const changeFavicon = (data: any) => {
    if (data?.site?.favicon) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        //@ts-ignore
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      //@ts-ignore
      link.href = data.site.favicon;
    }
  }

  const handleFetchData = async () => {
    let baseurl = process.env.REACT_APP_API_BASE_URL;

    const urlLang = getParameterByName("lang") || "en";
    let siteName = getParameterByName("site") || "";

    if (siteName?.length === 0) {
      const host = window.location.host.split(":")[0];
      if (host.toLocaleLowerCase() !== "localhost") {
        siteName = host;
      } else {
        siteName = "localhost";
      }
    }

    let url = `${baseurl}/domain/${siteName}?lang=${urlLang}`;

    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();

      if (data?.site?.oneTrust?.enabled) {
        addOneTrust(data?.site?.oneTrust?.id);
      }

      if (data?.custom?.css) {
        addCustomCss(data?.custom?.css);
      }
      if (data?.custom?.js) {
        addCustomJs(data?.custom?.js);
      }
      

      changeFavicon(data);

      setSiteSettings(data);
    } else {
      if (response.status === 400) {
        try {
          const data = await response.json();
          if (data.error === "Domain not found") {
            setExceptionMessage("Seems like the site for this domain is not setup yet!");
            return;
          }
        } catch (ex) {
          setExceptionMessage("Something went wrong");
          return;
        }
      }
      setExceptionMessage("Something went wrong");
      return;
    }

  }

  const [showDevGodMode, setShowDevGodMode] = useState(false);

  const openDevGodMode = (e: any) => {
    if (e.key === 'F9') {
      if (document.getElementsByClassName("god").length > 0) {
        setShowDevGodMode(false);
      } else {
        setShowDevGodMode(true);
      }
    }
  }

  var ranOnce = false;
  useEffect(() => {
    handleFetchData();
    if (!ranOnce) {

      document.addEventListener("keydown", openDevGodMode, false);
      // eslint-disable-next-line
      ranOnce = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function mapNavBarType(name: string) {
    //bet this can be simplified, one for later
    switch (name) {
      case "TopNavBarStyle1":
        return TopNavBarStyle1;
      case "TopNavBarStyle2":
        return TopNavBarStyle2;
      case "TopNavBarStyle3":
        return TopNavBarStyle3;
      default:
        return "pre";
    }
  }

  function mapFooterType(name: string) {
    //bet this can be simplified, one for later
    switch (name) {
      case "FooterStyle1":
        return FooterStyle1;
      case "FooterStyle2":
        return FooterStyle2;
      default:
        return "pre";
    }
  }

  function formatedCss() {
    let cssObj = {};
    // eslint-disable-next-line
    Object.assign({}, (Object.keys(siteSettings.site.colors).map(x => {
      /*@ts-ignore*/
      cssObj[`--app-${x}`] = siteSettings.site.colors[x];
    })));
    return cssObj;
  }


  return (
    <>
      {exceptionMessage.length > 0 && <h3>{exceptionMessage}</h3>}
      {exceptionMessage.length === 0 &&
        <div style={{
          direction: (siteSettings.site?.currentLanguage?.direction || "ltr" as any),
          ...formatedCss()
        }}>
          <HashRouter>
            {createElement(mapNavBarType(siteSettings.navBar.type), { settings: siteSettings.navBar, languages: siteSettings?.site?.languages })}
            <Container style={{ minHeight: "calc(100vh - 151px)", maxWidth: "880px", ...siteSettings?.site?.cssStyle }}>
              <ProgressBarStyle1 siteSettings={siteSettings} ></ProgressBarStyle1>
              <Container style={{ paddingTop: '20px', marginBottom: "50px" }}>
                <Routes>
                  {siteSettings.pages.map((page: any, i: number) =>
                    <Route key={i} path={page.path} element={<Page settings={page} />} />
                  )}
                </Routes>
              </Container>
              {(getParameterByName("godmode")?.toLowerCase() === "yup" || showDevGodMode) && <DevToolGodMode handleFetchData={handleFetchData}></DevToolGodMode>}
            </Container>
            {createElement(mapFooterType(siteSettings.footer.type), { settings: siteSettings.footer })}
          </HashRouter>
        </div >
      }
    </>
  );
}

export default App;
